<template>
  <section class="TicketRequest-View">
    <div class="ticket-request__section-success-request" v-if="isTicketRequestCreatedSuccessfully">
      <Headline
          :class="[
            'ticket-request__section-headline',
            'ticket-request__section-headline_centered'
          ]"
          :size="3"
      >
        Your request was submitted successfully!
      </Headline>
      <div class="ticket-request__section-check-container">
        <img
            class="ticket-request__section-check"
            :src="$static.icons.check"
            alt="icon"
        />
      </div>
    </div>
    
    <template v-else>
      <Headline size="1">Request match tickets</Headline>

      <Section :style="{marginTop: '40px'}">
        <b-row class="mt-4" cols="1" :cols-xl="steps.length">
          <b-col v-for="(step, i) in steps" :key="i" class="mb-5">
            <div class="d-flex align-items-center mb-4">
              <span class="step-number">{{ i + 1 }}</span>
              <span class="step-title">{{ step }}</span>
            </div>
            <div class="step-content" v-if="i === 0">
              <p class="mb-4">
                Do you want to request complimentary tickets
                or purchase tickets?
              </p>

              <div class="mb-4">
                <input type="radio" v-model="ticketsType" value="complimentary" id="complimentary"/>
                <label for="complimentary" class="m-0">Complimentary tickets</label>
              </div>
              <div>
                <input type="radio" v-model="ticketsType" value="purchase" id="purchase"/>
                <label for="purchase" class="m-0">Purchase tickets</label>
              </div>
            </div>
            <div class="step-content" v-if="i === 1">
              <p class="mb-4">
                Select the matches you want to request tickets for!
              </p>

              <InputDropdownMultiselect
                v-model="matchDays"
                :options="matchDaysList"
                placeholder="Select matchday"
                trackBy="matchdayId"
                trackLabel="optionLabel"
                group-select
                group-values="items"
                group-label="label"
                :limit="1"
                class="inputWrapper"
                keepOpen
              />

              <InputDropdownMultiselect
                v-model="matches"
                :options="matchesList"
                placeholder="Select match"
                trackBy="matchId"
                trackLabel="name"
                group-select
                group-values="items"
                group-label="label"
                :limit="1"
                class="inputWrapper"
                keepOpen
                :disabled="!matchDays.length"
              />

              <Button type="outline-primary" :disabled="!matchDays.length || !matches.length" @click="addMatches">Add match(es)</Button>
              <Button type="outline-primary" class="no-border ml-3" @click="clearAll" v-if="selectedMatches.length">Clear</Button>
            </div>
            <div class="step-content" v-if="i === 2">
              <p class="mb-4">
                Select your amount of tickets per zone and submit your request!
              </p>
            </div>
          </b-col>
        </b-row>

        <TicketsMatch
          v-for="(match, i) in selectedMatches"
          :key="match.matchId"
          :match="match"
          :ticketsType="ticketsType"
          @removeMatch="removeMatch"
          @update="calculateTotals"
        />

        <div class="d-flex justify-content-end font-weight-bold mt-4 mr-5 pr-2 totals" v-if="selectedMatches.length">
          <div class="pt-1" v-if="isComplimentaryMode">
            <span>Total tickets:</span>
            <span class="ml-1">{{ totals.all || 0 }}</span>
          </div>
          <ul class="m-0" v-else>
            <li v-for="(number, currency) in totals" v-bind:key="currency" class="text-right pt-1">
              <span>Total:</span>
              <span class="ml-1">{{ number.toFixed(2) }}</span>
              <span class="ml-1">{{ currency }}</span>
            </li>
          </ul>
        </div>

        <div class="mt-4 text-center font-weight-bold text-danger" v-if="hasMissingContacts">
          You haven't defined all your Contacts for the matches yet.<br/>
          Before you proceed, please create your contacts first.
        </div>

        <div class="mt-4 text-center font-weight-bold text-danger" v-if="hasExceededQuotas">
          You are exceeding your ticket quota.<br/>
          Before you proceed, please adjust your request.
        </div>

        <b-row align-h="center" class="mt-4">
          <b-col cols="auto">
            <Button
              @click="submit"
              :disabled="!selectedMatches.length || hasMissingContacts || hasExceededQuotas"
              v-if="!showLoader"
            >
              Submit Request
            </Button>
            <pulse-loader :loading="showLoader" :color="loaderColor"></pulse-loader>
          </b-col>
        </b-row>
      </Section>
    </template>

    <div class="btn-wrapper" v-if="isTicketRequestCreatedSuccessfully">
      <Button type="primary" @click="goToTicketRequestsList()">
        Back
      </Button>
    </div>
  </section>
</template>

<script>
import Headline from '@/components/Headline/Headline.component';
import Section from '@/components/Section/Section.component';
import InputDropdownMultiselect from "@/components/Inputs/InputDropdownMultiselect.component";
import TicketsMatch from '@/components/Tickets/TicketsMatch.component';
import RouterNames from "@/router/route.names";
import Button from "@/components/Button/Button.component";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import icon from "@/assets/DDMCAF/images/check.svg";
import set from "lodash.set";
import get from "lodash.get";
import {DtoApiRequestTicketsRequest} from "@/classes/dto/api/ticket/dto.api.request-tickets.request";

export default {
  components: {
    Headline, Section, InputDropdownMultiselect, TicketsMatch, Button, PulseLoader
  },
  data: () => ({
    ticketsType: 'complimentary',
    isTicketRequestCreatedSuccessfully: false,
    steps: [
      'Complimentary or purchase',
      'Select matches',
      'Submit request'
    ],
    showLoader: false,
    loaderColor: '#00A9A0',
    totals: {},
    hasExceededQuotas: false,
    hasMissingContacts: false,
  }),
  watch: {
    matchDays(newVal) {
      const matchdayIds = this.matchDays.map(matchDay => matchDay.matchdayId);
      if (newVal) {
        this.getMatches({ matchdayIds })
      }
      this.matches = this.matches.filter(match => matchdayIds.includes(match?.matchday.matchdayId));
    },
    matches(newVal) {
      if (newVal) {
        // add logic on match change here
      }
    }
  },
  computed: {
    matchDaysList() {
      const matchDays = get(this, '$store.state.event.matchDaysList');

      return [
        {
          label: 'All matchdays',
          items: matchDays,
        },
      ];
    },
    matchesList() {
      const selectedMatchIds = this.selectedMatches.map(match => match.matchId);
      const matches = get(this, '$store.state.event.matchesList'); //.filter(match => !selectedMatchIds.includes(match.matchId));

      matches.forEach(match => {
        if (!match.isAccessible) {
          match.$isDisabled = true;
          // match.name = `${match.title} (not accessible yet)`;
          return;
        }
        if (selectedMatchIds.includes(match.matchId)) {
          // match.name = `${match.title} (already selected)`;
          match.$isDisabled = true;
          return;
        }
      });

      return [
        {
          label: 'All matches',
          items: matches,
        },
      ];
    },

    matchDays: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.matchDays
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.matchDays = newVal
      }
    },
    matches: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.matches
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.matches = newVal
      }
    },
    selectedMatches: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.selectedMatches
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.selectedMatches = newVal
      }
    },

    organizationId() {
      return get(this, '$store.state.user.account.id')
    },

    isComplimentaryMode(){
      return (this.ticketsType === 'complimentary');
    },
  },
  methods: {
    getMatches(data) {
      this.$store.dispatch('event/getMatches', {...data, limit: 0, requestType: 'ticket_request'})
    },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToTickets'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    goToTicketRequestsList() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestList
      this.$router.push({name})
    },
    addMatches() {
      this.selectedMatches.push(...JSON.parse(JSON.stringify(this.matches)));
      this.selectedMatches.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime());
      this.clearSelection(true);
      this.calculateTotals();
    },
    removeMatch(match) {
      const index = this.selectedMatches.findIndex(m => m.matchId === match.matchId);
      if(index >= 0){
        this.selectedMatches.splice(index, 1);
      }
      this.calculateTotals();
    },
    clearMatches() {
      this.selectedMatches = [];
      this.calculateTotals();
    },
    clearAll() {
      this.clearSelection();
      this.clearMatches();
    },
    clearSelection(keepMatchdays = false) {
      this.matches = [];
      if(!keepMatchdays){
        this.matchDays = [];
      }
    },
    async submit() {
      if(this.hasMissingContacts || this.hasExceededQuotas){
        return;
      }

      this.showLoader = true;

      await Promise.all(this.selectedMatches.filter(match => !match.submitted).map(match => {
        const payload = {
          type: this.ticketsType,
          organizationId: this.organizationId,
          deliveryContactId: match.deliveryContact?.ticketContactId || null,
          mainContactId: match.mainContact?.ticketContactId || null,
          matchId: match.matchId,
          zones: match.zones?.map(({quantity, ticketZoneId}) => ({
            zoneId: ticketZoneId,
            quantity: Number(quantity),
          })) || [],
        };

        this.$set(match, 'submitRequest', new DtoApiRequestTicketsRequest().$post(payload).then(result => {
          if (result.$status === 200) {
            this.$set(match, 'submitted', true);
          }
          this.$set(match, 'submitRequest', null);
        }), err => {
          this.$set(match, 'submitRequest', null);
        });

        return match.submitRequest;
      }));

      this.showLoader = false;

      if(this.selectedMatches.filter(match => !match.submitted).length === 0){
        this.clearAll();
        this.isTicketRequestCreatedSuccessfully = true;
      }
    },
    calculateTotals() {
      const totals = {};

      this.selectedMatches.forEach(match => {
        if(!match.total){
          return;
        }

        let key;
        if(this.isComplimentaryMode){
          key = 'all';
        }
        else {
          key = match.currency;
        }

        if(!key){
          return;
        }

        if(!totals[key]){
          totals[key] = 0;
        }
        totals[key] += match.total;
      });

      this.totals = totals;

      this.checkQuotas();
      this.checkMissingContacts();
    },
    checkMissingContacts() {
      this.hasMissingContacts = this.selectedMatches.filter(match => !match.deliveryContact?.ticketContactId || !match.mainContact?.ticketContactId).length > 0
    },
    checkQuotas() {
      if(!this.isComplimentaryMode){
        this.hasExceededQuotas = false;
        return;
      }

      this.hasExceededQuotas = this.selectedMatches.filter(match => match.hasComplimentaryQuota && match.zones.filter(zone => Number(zone.quantity) > zone.quota).length > 0).length > 0
    },
    closeTooltips(e) {
      //close all open tooltips when clicking outside of them
      if(!e.target.closest('.tooltip')) {
        this.$root.$emit('bv::hide::tooltip');
      }
    },
  },
  created() {
    this.checkPermissions()
    set(this, '$static.icons.check', icon);
    this.$store.dispatch('event/getMatchDays', {hasMatchesForTicketRequest: 1})
    document.addEventListener('click', this.closeTooltips);
  },
  destroyed() {
    document.removeEventListener('click', this.closeTooltips);
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import "../../../styles/variables.scss";
@import "../../../styles/mixins/ellipsis.mixin";
@import "../../../styles/mixins/radio.mixin";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.TicketRequest-View {
  min-width: 550px;
  font-size: 12px;

  @include radio();

  .step-number,
  .step-title {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
  }

  .step-number {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $secondary-color;
    color: #fff3cd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .step-content {
    line-height: normal;
  }

  .totals {
    font-size: 14px;
  }

  .inputWrapper {
    max-width: 500px;
  }

  .ticket-request__section {
    &-success-request {
      background-color: white;
      padding: 32px 24px;
      height: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
    }
    &-headline {
      display: flex;

      &_centered {
        justify-content: center;
      }
    }

    &-check {
      width: 130px;
      margin: auto;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
      }
    }
  }

  ::v-deep .InputLabel__label {
    @include ellipsis;
    min-width: 166px;
    max-width: 166px;
    width: 166px;
    font-size: 12px;
    font-weight: bold;
  }

  ::v-deep .InputLayout__slot {
    width: 100%;

    .multiselect {
      width: 100%;
    }
  }

  ::v-deep .multiselect--disabled {
    background: none;

    .multiselect__select {
      background: none;
    }
  }
}
</style>

